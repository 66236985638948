body.dark {
    background-color: #171717 !important;
    color: white;

    input:focus {
        box-shadow: 0 0 10px #ffffff0c;
    }
}

h1.dark,
h2.dark,
h3.dark,
h4.dark,
h5.dark,
h6.dark {
    color: white;
}

.dark {
    &::-webkit-scrollbar {
        width: 0.5rem;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: #222222;
        border-radius: 100px;

        &:hover {
            background: #303030;
        }
    }

    .gradien-down::before {
        background: linear-gradient(180deg, #171717, #17171700);
    }

    .gradien-up::after {
        background: linear-gradient(0deg, #171717, #17171700);
    }

    #root > .menu {
        background-color: #0d0d0d;

        ul a,
        .btn {
            color: white;
        }
    }

    .header {
        .btn {
            color: white;
        }

        .search input {
            color: white;
            background-color: #0d0d0d;

            &::placeholder {
                color: #ffffff80;
            }
        }
    }

    .main {
        .category .item {
            background-color: #0d0d0d;
            color: white;
        }

        .row .col .menu-item {
            color: white;
            background-color: #0d0d0d;
        }
    }

    .footer {
        color: white;

        .payment .method {
            padding: 0.75rem 2rem;
            background-color: #0d0d0d;
            color: white;
            margin: 0 1rem;
            border-radius: 0.5rem;
        }

        .countup svg {
            color: white;
        }
    }

    .container > .paysum, .container > .paytime {
        background-color: #171717;
        color: white;
    }

    .orders {
        .order {
            border-color: #f5f5f5;
            .order__item {
                border-color: #f5f5f591;
            }
        }
        .btn-show {
            color: white;
        }
        .btn-submit, .form-select {
            border-color: #f5f5f591 !important;
            &:hover {border-color: #f5f5f5 !important;}
        }
        .btn-submit {border-color: #f5f5f5;}
    }

    .overview {
        table {
            th, tfoot {background: #252525;border-color: #f5f5f5;}
            td {border-color: #f5f5f5;}
        }
        .btn-submit {border-color: #f5f5f5;}
        .overview__product-content {
            background: black;
        }
    }

    .paysum {
        .summary .list .itemlist {
            border-bottom: 2px solid #ffffff80;

            .item {
                border-bottom: 1px dotted #ffffff80;

                .item-props .btn {
                    background-color: #0d0d0d;
                    color: white;
                }
            }
        }

        .methods .method {
            background-color: #0d0d0d;
            color: white;
        }

        .summary .list .countup svg {
            color: white;
        }
    }

    .main .item-category .itemlist .item {
        border-color: #ffffff80;

        > div > button.btn-normal {
            background-color: #0d0d0d;
            color: white;
        }
    }

    .keypad {
        background: #0d0d0d;
        box-shadow: 0 0 10px #ffffff12 !important;
        &.paytime__keypad {
            box-shadow: none !important;
        }

        .close {
            color: white;
        }

        .count-tab .count {
            background-color: #171717;
        }

        .keys .btn-digit {
            color: white;
            background-color: #171717;

            &.decrease {
                color: white;
                background-color: #df15154d;
            }

            &.submit {
                color: #0cb1a0;
                background-color: #0cb1a026;
            }
        }
    }

    .form {
        .form-group {
            input {
                color: white;
                border-color: #f5f5f5 !important;
                color-scheme: dark;
            }

            .form-select {
                border-color: #f5f5f5 !important;
                color: white;
                color-scheme: dark;
            }

            option {
                color: black;
            }
        }

        input:focus {
            border-bottom: 1px solid white !important;
            box-shadow: none !important;
            background-color: transparent;
        }

        select:focus {
            border: 1px solid white !important;
        }
    }

    button {
        &.btn {
            color: #f5f5f5;
        }

        &.btn:hover {
            color: white;
        }

        &.btn-cancel {
            border-color: #f5f5f5da;
            color: white;
        }
    }

    .status-msg {
        background-color: #0d0d0d;
        color: white;
    }

    .reset-link {color: #f5f5f5;}
}
