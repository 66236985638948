.settings {

    .setting {
        &__header {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            h3 {margin-bottom: 0; margin-left: 1rem;}
        }
        &__body {
            h2 {margin-top: 1rem; font-size: 1.75rem;}
        }
        .turnus {
            border: 1px solid black;
            border-radius: .5rem;
            padding: 1rem;
            margin: .5rem;
            width: 70%;
            position: relative;
            h3 {margin: 0; font-size: 1.5rem;}
            p {margin: 0;}
            .btn {position: absolute; right: 1rem; top: 50%; transform: translateY(-50%);}
            &.passed {opacity: .5;}
            &.current {border-color: #0cb1a0; background: #0cb1a026;}
        }

        &__body {
            .userlist {

            }
        }
    }
}