.container > .paysum {
    position: absolute;
    bottom: -100%;
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
    z-index: 50;
    transition: 0.5s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;

    &.open {
        bottom: -5rem;
    }
}

.paysum {
    > div {
        width: 50%;
    }

    h2 {
        margin-bottom: 1.5rem;
    }

    .summary {
        padding: 1rem 0.75rem;

        .list {
            .itemlist {
                margin: 0;
                padding: 0;
                border-bottom: 2px solid #00000080;
                overflow-y: scroll;
                height: 100%;
                max-height: 65vh;

                &::-webkit-scrollbar {
                    display: none;
                }

                .item {
                    margin: 0;
                    padding: 1rem 0;
                    border-bottom: 1px dotted #00000080;

                    &:last-child {
                        border-bottom: none;
                    }

                    .item-name {
                        font-weight: 700;
                    }

                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: row;

                    > div,
                    .item-props {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        flex-direction: row;
                    }

                    > div > p {
                        margin-right: 1.5rem;
                    }

                    p {
                        margin: 0;
                    }

                    .item-props {
                        .item-amout {
                            margin: 0 15px;
                            font-weight: bold;
                        }

                        .btn {
                            background-color: white;
                        }
                    }
                }
            }

            .countup {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: row;

                div {
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    flex-direction: column;

                    p {
                        margin: 0;
                        padding: 0;
                    }

                    .price {
                        font-weight: bold;
                        font-size: 1.75rem;
                    }
                }

                svg {
                    font-size: 3rem;
                    padding: 1rem;
                }
            }
        }
    }

    .methods {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        height: 100%;

        .method {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background-color: white;
            margin: 1rem;
            border-radius: 0.5rem;
            height: 12rem;
            width: 12rem;

            svg {
                font-size: 3rem;
                margin: 1rem;
            }
        }
    }
}
