/* Modal */

.ReactModal__Overlay {
    background-color: transparent !important;
    z-index: 500;
}

.ReactModal__Content {
    background-color: transparent !important;
    inset: 0 !important;
    padding: 0 !important;
    width: 100vw;
    height: 100vh;
    border: 0 !important;
}

/* Toastify */

.Toastify__toast-container {
    width: 20% !important;
    bottom: 1rem !important;
    right: 1rem !important;
    top: auto !important;

    @media only screen and (max-width: 500px) {
        width: calc(100% - 2rem) !important;
        bottom: 1rem !important;
        right: 1rem !important;
        left: auto !important;
    }
}

/* Offline */

.status-msg {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    padding: 1.5rem 5rem;
    border-radius: 0.5rem;
    font-size: 1.5rem;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    z-index: 1000;

    &.offline {
        bottom: -10rem;
        animation: popUp 0.5s ease-in-out forwards;
    }

    &.online {
        bottom: 2rem;
        animation: popDown 0.5s 1s ease-in-out forwards;
    }

    p {
        margin-left: 2rem;
        margin-bottom: 0;
    }
}

.online svg {
    color: lime;
    font-size: 2rem;
}

.offline svg {
    color: red;
    font-size: 2rem;
}
