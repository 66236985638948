.container .keypad {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    min-width: 295px;
    z-index: 20;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px #00000012 !important;
    padding: 2.5rem;

    @media only screen and (max-width: 500px) {
        padding: 1.5rem;
    }
}

.keypad {
    .keys {
        > .row {
            width: 100%;
            min-width: 340px;
        }

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0;
        margin: 0;
    }

    .name {
        text-align: center;
        width: 100%;
        font-size: 1.5rem;
        font-weight: 700;
        max-width: 320px;
        margin: auto;
    }

    .count-tab .count {
        height: 75px;
        background-color: #f5f5f5;
        border-radius: 0.5rem;
        margin: 5px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        p {
            margin: 0;
            padding: 0 1rem;
            font-size: 2rem;
        }
    }

    .close {
        position: absolute;
        top: 0;
        right: 5px;
        padding: 5px;
        font-size: 1.5rem;
    }

    .col {
        height: 100%;
        width: auto;
    }

    .keys .btn-digit {
        &.decrease {
            color: #df1515;
            background-color: #df151526;
            height: 158px;
            float: right;
        }

        &.submit {
            height: 158px;
            float: right;
            font-size: 2.5rem;
            color: #0cb1a0;
            background-color: #0cb1a026;
        }
    }

    tr:last-child td .btn-digit {
        width: calc(100% - 10px);
    }
}
