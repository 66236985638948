@import './Animations.scss';
@import './Buttons.scss';
@import './Forms.scss';
@import './Keypad.scss';
@import './Loader.scss';
@import './Orders.scss';
@import './Overview.scss';
@import './Paytime.scss';
@import './Paysum.scss';
@import './ReactLibComponents.scss';
@import './Setting.scss';
@import './User.scss';
@import './Login.scss';
@import './darktheme.scss';

$breakpoints: (small: 320px, medium: 768px, large: 1024px);

html,
body {
    margin: 0;
    padding: 0;
    background-color: #f5f5f5 !important;
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: "Raleway" !important;
    font-style: normal;
    font-weight: normal;
    overflow: hidden;
}

input:focus,
select:focus {
    outline: 0;
    box-shadow: 0 0 10px #00000012 !important;
    border-color: transparent !important;
}

.container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    padding: 0 !important;

    > div {
        position: relative;
        width: 100%;
        padding: 1rem 2rem;
    }
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 700 !important;
}

.hidden {
    display: none !important;
}

.error-site {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
        font-size: 4rem;
    }

    p {
        font-size: 2rem;
    }
}

/* Scrollbar */

::-webkit-scrollbar {
    width: 0.5rem;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: #dadada;
    border-radius: 100px;

    &:hover {
        background: #cecece;
    }
}

/* Menu */

#root > .menu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: -30%;
    width: 20vw;
    min-width: 350px;
    z-index: 1000;
    background-color: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: 0.5s;

    &.open {
        left: 0;
    }

    .close-menu {
        position: absolute;
        top: 0;
        right: 0;
        padding: 1rem;
        font-size: 2rem;
    }

    .other-options {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        padding: 1rem;
        font-size: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
    }

    ul {
        margin: 0;
        margin-left: 1rem;
        padding: 0;
        list-style: none;

        li {
            margin: 1rem;
        }

        a {
            color: black;
            text-decoration: none;
            font-size: 2rem;
            padding: 1rem;
            cursor: pointer;
        }
    }

    @media only screen and (max-width: 500px) {
        left: -90%;

        ul li {
            margin: .5rem 1rem;
            a {
                font-size: 1.5rem;
                padding: .5rem 1rem;
            }
        }
    }
}

/* Header */

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    .btn {
        font-size: 1.5rem;
        color: black;
    }

    .account .btn {
        padding: 0;
        .user-image {
            width: 4rem;
            border-radius: 200px;
        }
    }

    .search {
        position: relative;
        width: 80%;

        input {
            width: 100%;
            background-color: white;
            border: none;
            border-radius: 0.5rem;
            padding: 1rem;
            font-size: 1rem;
            text-align: center;
            transition: 0.3s;

            &::placeholder {
                color: #00000080;
            }
        }

        .btn {
            position: absolute;
            top: 0;
            left: 0;
            padding: 0.75rem 1.25rem;

            &.close {
                left: auto;
                right: 0;
                transition: 0.3s;
            }
        }
    }

    @media only screen and (max-width: 500px) {
        .account .btn .user-image {
            width: 3rem;
        }

        .search {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 55%;
            transition: .5s;

            input {
                padding: .75rem;
            }

            &:focus-within {
                width: 90%;
            }

            .btn {
                display: none;
            }
        }
    }
}

/* Gradients */

.gradien-down::before {
    position: absolute;
    bottom: -2rem;
    left: 1rem;
    right: 1rem;
    height: 2rem;
    content: "";
    background: linear-gradient(180deg, #f5f5f5, #f5f5f500);
    z-index: 5;
}

.gradien-up::after {
    position: absolute;
    top: -2rem;
    left: 1rem;
    right: 1rem;
    height: 2rem;
    content: "";
    background: linear-gradient(0deg, #f5f5f5, #f5f5f500);
    z-index: 5;
}

/* Main */

.main {
    height: 100%;
    overflow-y: scroll;
}

.stock::-webkit-scrollbar {
    display: none;
}

.main {
    &__name {font-size: 3rem; font-weight: 700; margin-bottom: 0;}
    &__user-info {font-size: 2rem; margin-bottom: 0;}
    &__time {font-size: 2rem; font-weight: 700;}

    @media only screen and (max-width: 500px) {
        &__name {font-size: 2rem;}
        &__user-info {font-size: 1.25rem;}
        &__time {font-size: 1rem;}
    }

    .category {
        padding: 1rem 0.75rem;

        h2 {
            font-size: 1.5rem;
            margin-bottom: 1rem;
        }

        .item {
            background-color: white;
            border-radius: 0.5rem;
            cursor: default;

            &.disabled {
                opacity: .2;
            }

            .item-name {
                text-align: center;
            }

            .item-info {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                padding: 0.5rem;
                height: 7rem;

                .item-price {
                    font-size: 1.25rem;
                    font-weight: 700;
                }

                p {
                    margin: 0;
                }
            }

            .item-props {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 1.25rem;

                p {
                    margin: 0;
                    line-height: 1;
                    max-width: 2.5rem;
                    overflow: hidden;
                }

                .btn {
                    display: grid;
                    place-items: center;
                    padding: 1rem;
                }
            }
        }
    }

    .row .col .menu-item {
        text-decoration: none;
        font-size: 2rem;
        color: black;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 15rem;
        height: 15rem;
        margin: 1rem;
        border-radius: 1rem;

        p {
            margin: 0;
        }

        svg {
            font-size: 5rem;
            margin: 0.5rem;
        }
    }

    @media only screen and (max-width: 500px) {
        .row .col {flex-basis: auto;}
        .row .col .menu-item {
            flex-direction: row;
            flex-wrap: nowrap;
            height: auto;
            width: 100%;
            padding: 1rem;
            margin: .5rem 0;
            font-size: 1.5rem;
            svg {font-size: 2rem;}
        }
    }


    &.stock .row .col {
        overflow-y: scroll;
        height: 100%;
        max-height: 85vh;
    }

    .item-category {
        margin: 1rem 0.75rem;
        margin-top: 2rem;

        &:first-child {
            margin: 1rem 0.75rem;
        }

        .itemlist {
            margin: 0;
            padding: 0;

            .item {
                margin: 0;
                padding: 0.5rem 0;
                border-bottom: 1px dotted #00000080;

                &:last-child {
                    border-bottom: none;
                }

                .item-name {
                    font-weight: 700;
                }

                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: row;

                > div {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: row;

                    > {
                        p {
                            margin: 0.5rem;
                        }

                        button {
                            margin: 0.5rem;

                            svg {
                                width: 1rem;
                            }

                            &:last-child {
                                margin-right: 0;
                            }

                            &.btn-normal {
                                background-color: white;
                            }
                        }
                    }
                }

                p {
                    margin: 0;
                }
            }
        }
    }

    &.stock {
        @media only screen and (max-width: 500px) {
            .stock-form, .btn-edit {display: none !important;}
        }
    }
}

/* Footer */

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    > div {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .countup {
        div {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            flex-direction: column;
            margin: 0.5rem;

            p {
                margin: 0;
                padding: 0;
            }

            .price {
                font-weight: bold;
                font-size: 1.75rem;
            }
        }

        svg {
            font-size: 3rem;
            padding: 1rem;
        }
    }

    .payment .method {
        padding: 0.75rem 2rem;
        background-color: white;
        margin: 0 1rem;
        border-radius: 0.5rem;

        &:last-child {
            margin-right: 0;
        }

        svg {
            margin-right: 0.5rem;
        }
    }

    @media only screen and (max-width: 500px) {
        .countup {
            width: 100%;
            div {flex-direction: row; width: 100%; justify-content: space-evenly;}
            div .price {font-size: 1.15rem;}
            div p {font-size: 1.15rem;}
            svg {font-size: 1.75rem;}
        }
        .payment .method {
            display: none;
        }
        padding-bottom: 3.5rem !important;
    }
}
