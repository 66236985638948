.shake:hover {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

@keyframes shake {
    10%, 90% {transform: translate3d(-1px, 0, 0);}
    20%, 80% {transform: translate3d(2px, 0, 0);}
    30%, 50%, 70% {transform: translate3d(-4px, 0, 0);}
    40%, 60% {transform: translate3d(4px, 0, 0);}
}

@keyframes loading {
    to {transform: rotate(360deg);}
}

@keyframes popUp {
    to {bottom: 2rem;}
}

@keyframes popDown {
    to {bottom: -10rem;}
}

@keyframes letterRotate1 {
    0%, 15%, 100% {transform: rotateX(0deg) rotateY(0deg);}
    17% {transform: rotateX(90deg) rotateY(0deg);}
    96% {transform: rotateX(90deg) rotateY(90deg);}
    99% {transform: rotateX(0deg) rotateY(90deg);}
}

@keyframes letterRotate2 {
    0%, 15%, 100% {transform: rotateX(-90deg) rotateY(0deg);}
    17%, 31% {transform: rotateX(0deg) rotateY(0deg);}
    33% {transform: rotateX(0deg) rotateY(-90deg);}
    96% {transform: rotateX(-90deg) rotateY(-90deg);}
    98% {transform: rotateX(-90deg) rotateY(0deg);}
}

@keyframes letterRotate3 {
    0%, 31%, 100% {transform: rotateX(0deg) rotateY(-90deg);}
    33%, 49% {transform: rotateX(0deg) rotateY(0deg);}
    51% {transform: rotateX(90deg) rotateY(0deg);}
    96% {transform: rotateX(90deg) rotateY(-90deg);}
}

@keyframes letterRotate4 {
    0%, 49%, 100% {transform: rotateX(-90deg) rotateY(0deg);}
    51%, 65% {transform: rotateX(0deg) rotateY(0deg);}
    66% {transform: rotateX(0deg) rotateY(-90deg);}
    96% {transform: rotateX(-90deg) rotateY(-90deg);}
}

@keyframes letterRotate5 {
    0%, 65%, 100% {transform: rotateX(0deg) rotateY(90deg);}
    66%, 82% {transform: rotateX(0deg) rotateY(0deg);}
    84% {transform: rotateX(90deg) rotateY(0deg);}
    96% {transform: rotateX(90deg) rotateY(90deg);}
}

@keyframes letterRotate6 {
    0% {transform: rotateX(0deg) rotateY(-90deg);}
    20% {transform: rotateX(-90deg) rotateY(-90deg);}
    21%, 82% {transform: rotateX(-90deg) rotateY(0deg);}
    84%, 98% {transform: rotateX(0deg) rotateY(0deg);}
    100% {transform: rotateX(0deg) rotateY(-90deg);}
}

@keyframes loginForm {
    0% {transform: translateY(50%); opacity: 0;}
    100% {transform: translate(0%); opacity: 1;}
}
