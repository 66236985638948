.user {
    & > div {
        h2 {
            margin-bottom: 2rem;
        }
        border-bottom: 1px solid #181818;
        width: 50%;
        min-width: 500px;
        padding: 2rem 0;
        &:last-child {border-bottom: none;}
    }

    &__photo {
        .user__preview {
            width: 4rem;
            height: 4rem;
            margin-right: 1rem;
            border-radius: 5rem;
        }
    }
}