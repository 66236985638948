button {
    &.btn {
        border: 0;
        transition: 0.3s;
        border-radius: 0.5rem;

        &:hover {
            color: black;
        }

        &:focus {
            box-shadow: none;
        }
    }

    &.btn-increase {
        border-radius: 0.5rem;
        color: #0cb1a0;
        background-color: #0cb1a026;
    }

    &.btn-decrease {
        border-radius: 0.5rem;
        color: #df1515;
        background-color: #df151526;
    }

    &.btn-increase:hover {
        color: #0cb1a0;
    }

    &.btn-decrease:hover {
        color: #df1515;
    }

    &.btn-submit {
        border-radius: 0.5rem;
        color: white;
        background-color: #0d0d0dda;
        padding: 0.5rem 1.5rem;

        &:hover {
            background-color: #0d0d0d;
            color: white;
        }
    }

    &.btn-cancel {
        border: 1px solid #0d0d0dda;
    }

    &.btn-digit {
        margin: 5px;
        width: 75px;
        height: 75px;
        border-radius: 0.5rem;
        font-weight: 700;
        background-color: #f5f5f5;
        border: none;
        font-size: 1.5rem;
    }

    &.btn-submit.active {
        background: #1d1d1dc4;
    }

    & .loader {
        animation: loading 1.5s linear infinite;
        width: 2rem;
        margin: auto !important;
        padding: .5rem;
    }
}
