.form {
    width: 60%;

    @media only screen and (max-width: 1024px) {
        width: 70%;
    }

    .form-group {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin: 1.5rem 0;
        width: 100%;

        input,
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:active{
            font-size: 1rem;
            color: black;
            padding: 0.5rem;
            background-color: transparent !important;
            border: none;
            border-bottom: 1px solid #0d0d0d80;
            width: 100%;
        }

        .form-select {
            background-color: transparent;
            border: 1px solid #0d0d0d80;
        }
    }

    input:focus,
    input:-webkit-autofill:focus {
        border-bottom: 1px solid #0d0d0d80 !important;
        box-shadow: none !important;
        background-color: transparent;
    }

    select:focus {
        border: 1px solid #0d0d0d80 !important;
    }

    .btn {
        width: 100%;
        margin: 0.5rem 0;

        svg {
            margin-right: 0.5rem;
        }
    }
}