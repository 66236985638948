.login {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__header {
        margin-bottom: 2rem;
        opacity: 0;
        transform: translateY(50%);
        animation: loginForm .5s .5s ease forwards;
        h1 {
            font-size: 3rem;
            max-width: 70%;
            margin: auto;
            text-align: center;
            &.no-wrap {max-width: 100%;}
        }
    }

    .form {
        width: 25%;
    }
    .form-group {
        opacity: 0;
        transform: translateY(50%);
        animation: loginForm .5s 1s ease forwards;
    }
    .form-group:nth-child(2) {
        opacity: 0;
        transform: translateY(50%);
        animation: loginForm .5s 1.3s ease forwards;
    }
    .form-group:nth-child(3) {
        opacity: 0;
        transform: translateY(50%);
        animation: loginForm .5s 1.6s ease forwards;
    }
    .btn-submit {
        opacity: 0;
        transform: translateY(50%);
        animation: loginForm .5s 1.6s ease forwards;
    }

    .reset-link {
        color: black;
        margin-top: 1rem;
        text-decoration: none;
        font-size: .9rem;
        opacity: 0;
        transform: translateY(50%);
        animation: loginForm .5s 1.9s ease forwards;
    }
}

@media only screen and (max-width: 500px){
    .login {
        &__header {
            margin-bottom: 1rem;
            h1 {font-size: 2rem;}
        }

        .form {
            width: 70%;
        }

        .form-group {
            text-align: center;
        }
    }
}