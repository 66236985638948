.overview {
    h2 {
        padding: 0 1rem;
        position: relative;
    }

    .reload {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: 1.5rem;
    }

    &__header > span {
        font-weight: normal;
        font-size: 1.25rem;
        padding: 0 1rem;
    }

    &__header {
        margin-bottom: 1rem;
    }

    &__list {
        padding: 1rem;
        list-style: none;
        height: calc(100% - 210px);
        overflow-y: scroll;
    }

    @media only screen and (max-width: 500px) {
        &__header span {
            width: 100%;
            font-size: 1rem;
        }
    }

    &__filters {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin: 1.5rem 1rem;

        .btn-submit {width: auto; margin: 0; margin-left: .5rem; border: 1px solid #0d0d0d80; background: transparent; color: black; padding: 6px 1.5rem;}
        .form-group {width: auto; height: fit-content; margin: 0; margin-right: .5rem;
            .form-select {border-radius: .5rem;}
            .form-date, .form-date:focus {
                background-color: transparent;
                border: 1px solid #0d0d0d80 !important;
                border-color: #0d0d0d80 !important;
                border-radius: .5rem;
                padding: 6px .5rem;
            }
        }
        .btn-submit:hover, .form-select:hover {
            border-color: #0d0d0d;
            color: black;
        }
        .btn-submit:hover {background: transparent;}

        @media only screen and (max-width: 500px) {
            flex-direction: column;
            margin: 1rem 0;
            .form-group, .btn-submit {width: 100%; margin: .5rem 0;}
        }
    }
    p {margin: 0;}

    &__body {
        max-height: 65vh;
        overflow-y: scroll;
        padding: 0 1rem;
    }

    table {
        width: 100%;
        position: relative;
        border: 1px solid #0d0d0db7;
        th {
            position: sticky;
            top: 0;
            background: #ececec;
            border: 1px solid #0d0d0db7;
        }
        tfoot {
            position: sticky;
            bottom: 0;
            background: #ececec;
            border: 1px solid #0d0d0db7;
        }
        td, th {
            min-width: 100%;
            padding: .5rem;
            border: 1px solid #0d0d0db7;
            &.red {color: #df1515;}
            &.green {color: #0cb1a0;}
        }
    }

    .overall-sale-sum {font-size: 1.25rem; margin-bottom: 1.5rem;}

    &__products-tab {
        & > div { margin-bottom: 1.5rem; }
        .overview__product-content {
            background: white;
            padding: 1rem;
            border-radius: 1rem;
            height: 100%;

            .product-name {font-size: 1.15rem; margin-bottom: .25rem; min-height: 50px;}
            .product-stock {
                &.green {color: #0cb1a0;}
                &.red {color: #df1515;}
            }
        }

        @media only screen and (max-width: 500px) {
            & > div {
                padding: 0 .25rem;
                margin-bottom: .5rem;
            }
            .overview__product-content {
                .product-name {font-size: 1rem;}
            }
        }
    }
}