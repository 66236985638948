.container > .paytime {
    position: absolute;
    bottom: -100%;
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
    z-index: 100;
    transition: 0.5s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;

    &.open {
        bottom: -5rem;
    }
}

.paytime {
    h1 {
        font-size: 2rem;
    }

    & > .btn-decrease {
        position: absolute;
        top: 1rem;
        left: 2rem;
        margin: 0 .75rem;
    }

    &__keypad {
        position: relative !important;
        top: auto !important;
        left: auto !important;
        transform: translate(0, 0) !important;
        margin-top: 1.5rem;
        padding: 1.5rem !important;

        .btn-backspace {
            height: 158px;
        }
    }

    .btn-submit {
        width: 400px;
        margin-top: 1.5rem;
        font-size: 2rem;
    }
}
