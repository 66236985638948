.orders {
    h2 {
        padding: 0 1rem;
    }

    &__header > span {
        font-weight: normal;
        font-size: 1.25rem;
        padding: 0 1rem;
    }

    &__header {
        margin-bottom: 1rem;
    }

    &__list {
        padding: 1rem;
        list-style: none;
        height: calc(100% - 210px);
        overflow-y: scroll;
    }
    &__filters {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin: 1.5rem 1rem;

        .btn-submit {width: auto; margin: 0; margin-left: .5rem; border: 1px solid #0d0d0d80; background: transparent; color: black; padding: 6px 1.5rem;}
        .form-group {width: auto; height: fit-content; margin: 0; margin-right: .5rem;
            .form-select {border-radius: .5rem;}
        }
        .btn-submit:hover, .form-select:hover {
            border-color: #0d0d0d;
            color: black;
        }
        .btn-submit:hover {background: transparent;}

        @media only screen and (max-width: 500px) {
            flex-direction: column;
            .form-group, .btn-submit {width: 100%; margin: .5rem 0;}
        }
    }
    p {margin: 0;}

    .order {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px dashed #00000080;
        &:last-child {border-bottom: none; margin-bottom: 0; padding-bottom: 0;}

        &__info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            >div {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                p {
                    margin: 0 1rem;
                }
            }
        }
        &__heading {
            font-size: 1.25rem;
            font-weight: normal !important;
            margin-right: 2rem;
            span {
                font-size: 1.75rem;
                font-weight: bold;
            }
        }
        &__item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: .5rem;
            list-style: circle;
            padding-bottom: .5rem;
            border-bottom: 1px dashed #00000044;
            &:last-child {border-bottom: none;}

            .item-name {
                width: 30%;
            }
            .item-amout, .item-price {
                width: fit-content;
                margin: 0 1rem;
            }
        }
        &__options .btn {
            margin-left: 1.5rem;
        }
        .btn-show {
            color: black;
        }

        @media only screen and (max-width: 500px) {
            &__heading {
                font-size: .9rem;
                margin-right: 1rem;
                span {font-size: 1rem;}
            }
        }
    }
}