.loader-col {
    height: auto !important;
}


.loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 5rem;
    padding: 1rem;
    &.h-100 {height: 100vh !important;}

    &.progress-loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparentize( black, .5);
        z-index: 500;
    }

    svg {
        transform: rotate(0deg);
        animation: loading 1s linear infinite;
    }

    &.logo {
        position: relative;
        .letter {
            position: absolute;
            width: 100px;
            height: 100px;
            background-color: red;
            display: grid;
            place-content: center;
            text-transform: uppercase;
            font-family: 'Montserrat';
            font-weight: 600;

            p {
                margin: 0;
            }

            &.gray {background: #9d9d9c; animation: letterRotate1 6s linear infinite; backface-visibility: hidden; transform: rotateX(0deg);}
            &.green {background: #6dc1ac; animation: letterRotate2 6s linear infinite; backface-visibility: hidden; transform: rotateX(-90deg);}
            &.orange {background: #ed6a34; animation: letterRotate3 6s linear infinite; backface-visibility: hidden; transform: rotateX(0deg);}
            &.red {background: #e50051; animation: letterRotate4 6s linear infinite; backface-visibility: hidden; transform: rotateX(90deg);}
            &.purple {background: #585fa8; animation: letterRotate5 6s linear infinite; backface-visibility: hidden; transform: rotateY(90deg);}
            &.pink {background: #ea5297; animation: letterRotate6 6s linear infinite; backface-visibility: hidden; transform: rotateX(-90deg);}
        }
    }
}